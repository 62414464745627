import React, { useEffect, useState, useRef } from 'react';
import { useUserContext } from '../../contexts/UserContext.js';
import { Table, Container, Row, Col, Button, Stack } from 'react-bootstrap';
import { format } from "date-fns";

import { getUsers } from '../../api/rest.js';

const CoachDashboard = () => {
    const user_context = useUserContext();
    const user = user_context.getUser();
    const initialized = useRef(false)

    const [students, setStudents] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (!initialized.current) {
            initialized.current = true

            if (user) {
                getUsers().then((data)=> {
                    setStudents(data);
                    console.log(data);
                }).catch(error => {
                    setError(error.message);
                }).finally(() => {
                    setLoading(false);
                })
            };
        }
      }, [user]);

    if (loading) return (<p>Loading ...</p>);
    if (error) return (<p>Error fetching users: {error}</p>);

    return (
        <Container fluid>
            <Row className="mb-3">
                <Col className="d-flex justify-content-end">
                    <Button href='#students/add'>Add student</Button>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col xs={12} lg={2} className="order-lg-1" color='primary'>
                    <Stack gap={3}>
                        <div className="p-2">First item</div>
                        <div className="p-2">Second item</div>
                        <div className="p-2">Third item</div>
                    </Stack>
                </Col>
                <Col xs={12} lg={10} className="order-lg-2">
                    <Table responsive bordered hover>
                        <thead>
                        <tr>
                            <th>Full name</th>
                            <th>Birth date</th>
                            <th>Email</th>
                            <th>Phone number</th>
                            <th>Date Joined</th>
                        </tr>
                        </thead>
                        <tbody>
                        {students.map(student => (
                        <tr key={student.id}>
                            <td>{student.full_name}</td>
                            <td>{student.profile?.birth_date ? format(new Date(student.profile.birth_date), "dd.MM.yyyy") : ""}</td>
                            <td>{student.email || ""}</td>
                            <td>{student.phone_number}</td>
                            <td>{format(new Date(student.date_joined), "dd.MM.yyyy") || ""}</td>
                        </tr>
                        ))}
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </Container>
    );
};

export default CoachDashboard;
