import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";

import { Row, Container, Button, Form, Col, FormGroup } from 'react-bootstrap';
import { PatternFormat } from 'react-number-format';

import { createUserAsStudent } from '../../api/rest.js'

const AddStudent = () => {
    const navigate = useNavigate();
    const [validated, setValidated] = useState(false);

    const [email, setEmail] = useState('');
    const [phone_number, setPhoneNumber] = useState('');
    const [temporary_password, setTemporaryPassword] = useState('');
    const [first_name, setFirstName] = useState('');
    const [middle_name, setMiddleName] = useState('');
    const [last_name, setLastName] = useState('');
    const [gender, setGender] = useState('');
    const [notes, setNotes] = useState('');
    const [errorDisplay, setErrorDisplay] = useState("");

    const onFormSubmit = (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.stopPropagation();
        } else {
            const formData = {
                email: email,
                phone_number: phone_number,
                temporary_password: temporary_password,
                gender: gender,
                first_name: first_name,
                middle_name: middle_name,
                last_name: last_name,
                notes: notes,
            };

            createUserAsStudent(formData).then((data)=> {
                navigate('/');
            }).catch((error)=> {
                setErrorDisplay(error.response.data.detail)
            })
        }

        setValidated(true);
    }

    return(
        <Container fluid>
            <Row className="justify-content-md-center mt-5">
                <Col xs={12} md={6}>
                    <Form noValidate validated={validated} onSubmit={onFormSubmit} method='POST'>
                        <Form.Group className="mb-3" controlId="formBasicPhoneNumber">
                            <Form.Label>Phone number</Form.Label>
                            <PatternFormat
                                id='phone'
                                type='tel'
                                format='+# (###) ###-##-##'
                                mask="_"
                                onValueChange={value => setPhoneNumber(value.formattedValue)}
                                required
                                className="form-control"
                                placeholder="Enter phone number"
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                                type="email"
                                required
                                placeholder='Enter email'
                                onChange={e => setEmail(e.target.value)
                            }/>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicTemporaryPassword">
                            <Form.Label>Temporary password</Form.Label>
                            <Form.Control
                                type="password"
                                required
                                minLength={8}
                                maxLength={32}
                                placeholder='Enter temporary password'
                                onChange={e => setTemporaryPassword(e.target.value)
                            }/>
                        </Form.Group>
                        <FormGroup className="mb-3" controlId="formBasicFirstName">
                            <Form.Label>Gender</Form.Label>
                            <div>
                                <Form.Check
                                    inline
                                    label="Male"
                                    value={"M"}
                                    id='genderMale'
                                    name="gender"
                                    type="radio"
                                    onChange={e => setGender('M')}
                                />
                                <Form.Check
                                    inline
                                    label="Female"
                                    value={"F"}
                                    id='genderFemale'
                                    name="gender"
                                    type="radio"
                                    onChange={e => setGender('F')}
                                />
                            </div>
                        </FormGroup>
                        <Form.Group className="mb-3" controlId="formBasicFirstName">
                            <Form.Label>First Name</Form.Label>
                            <Form.Control
                                type="text"
                                required
                                placeholder='Enter first name'
                                onChange={e => setFirstName(e.target.value)
                            }/>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicLastName">
                            <Form.Label>Last Name</Form.Label>
                            <Form.Control
                                type="text"
                                required
                                placeholder='Enter last name'
                                onChange={e => setLastName(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicMiddleName">
                            <Form.Label>Middle Name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder='Enter middle name (optional)'
                                onChange={e => setMiddleName(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicNotes">
                            <Form.Label>Notes</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={3}
                                maxLength={256}
                                placeholder='Enter notes (optional)'
                                onChange={e => setNotes(e.target.value)}
                            />
                        </Form.Group>
                        <Button variant="primary" type="submit" className="w-100">Add</Button>
                        <p style={{color: 'red'}}>{errorDisplay}</p>
                    </Form>
                </Col>
            </Row>
        </Container>
    );
};

export default AddStudent;
