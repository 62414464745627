import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";

import { Row, Container, Button, Form, Col } from 'react-bootstrap';

import { useUserContext } from '../../contexts/UserContext.js'
import { changeCurrentUserPassword } from '../../api/rest.js'


const ChangePassword = () => {
    const navigate = useNavigate();

    const user_context = useUserContext();
    const [validated, setValidated] = useState(false);

    const [new_password, setNewPassword] = useState('');
    const [confirm_password, setConfirmPassword] = useState('');
    const [errorDisplay, setErrorDisplay] = useState("")

    const onFormSubmit = (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.stopPropagation();
        } else {

            const request_data = {
                new_password: new_password,
                confirm_password: confirm_password
            };

            changeCurrentUserPassword(request_data).then((user)=> {
                user_context.setUser(user);
                navigate('/');
            }).catch((error)=>{
                const response_data = error.response.data;
                var error_message = '';
                if (response_data.detail) {
                    error_message = response_data.detail;
                } else if (response_data.non_field_errors) {
                    error_message = response_data.non_field_errors;
                } else if (response_data.new_password) {
                    error_message = response_data.new_password;
                } else {
                    error_message = "Unknown error";
                }

                setErrorDisplay(error_message);
            });
        }

        setValidated(true);
    }

    return(
        <Container fluid>
            <Row className="justify-content-md-center mt-5">
                <Col xs={12} md={6}>
                    <h2 className="text-center mb-4">Change Password</h2>
                    <Form noValidate validated={validated} onSubmit={onFormSubmit} method='POST'>
                        <Form.Group className="mb-3" controlId="formBasicNewPassword">
                            <Form.Label>New Password</Form.Label>
                            <Form.Control
                                type="password"
                                minLength={8}
                                maxLength={32}
                                required
                                onChange={e => setNewPassword(e.target.value)}
                                autoComplete='new-password'
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicConfirmPassword">
                            <Form.Label>Confirm Password</Form.Label>
                            <Form.Control
                                type="password"
                                minLength={8}
                                maxLength={32}
                                required
                                onChange={e => setConfirmPassword(e.target.value)}
                                autoComplete='new-password'
                            />
                        </Form.Group>
                        <Button variant="primary" type="submit" className="w-100">
                            Update
                        </Button>
                        <p style={{color: 'red'}}>{errorDisplay}</p>
                    </Form>
                </Col>
            </Row>
        </Container>
    );
};

export default ChangePassword;
