import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";

import { Row, Container, Button, Form, Col } from 'react-bootstrap';
import { PatternFormat } from 'react-number-format';

import { useUserContext } from '../../contexts/UserContext.js'
import { loginUser } from '../../api/auth.js'
import { getCurrentUser } from '../../api/rest.js'


const Login = () => {
    const navigate = useNavigate();

    const user_context = useUserContext();
    const [validated, setValidated] = useState(false);

    const [phone_number, setPhoneNumber] = useState('');
    const [password, setPassword] = useState('');
    const [errorDisplay, setErrorDisplay] = useState("");

    const onFormSubmit = (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.stopPropagation();
        } else {
            loginUser(phone_number, password).then((access_data)=> {
                getCurrentUser().then((data)=> {
                    user_context.setUser(data);

                    if (data.is_temporary_password) {
                        navigate('/change-password');
                    } else {
                        navigate('/');
                    }
                })
            }).catch((error)=>{
                if (error.response){
                    setErrorDisplay(error.response.data.detail);
                } else {
                    console.log(error)
                    setErrorDisplay({error});
                }
            });
        }

        setValidated(true);
    }

    return(
        <Container>
            <Row className="justify-content-md-center mt-5">
                <Col xs={12} md={6}>
                    <h2 className="text-center mb-4">Login</h2>
                    <Form noValidate validated={validated} onSubmit={onFormSubmit} method='POST'>
                        <Form.Group className="mb-3" controlId="formBasicPhone">
                            <Form.Label>Phone number</Form.Label>
                            <PatternFormat
                                id='phone'
                                type='tel'
                                format='+# (###) ###-##-##'
                                mask="_"
                                onValueChange={value => setPhoneNumber(value.formattedValue)}
                                required
                                className="form-control"
                                placeholder="Enter phone number"
                                autoComplete='username'
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Password</Form.Label>
                            <Form.Control
                                type="password"
                                placeholder="Password"
                                value={password}
                                required
                                onChange={e => setPassword(e.target.value)}
                                autoComplete='current-password'
                            />
                        </Form.Group>
                        <Button variant="primary" type="submit" className="w-100">
                            Submit
                        </Button>
                        <p style={{color: 'red'}}>{errorDisplay}</p>
                    </Form>
                </Col>
            </Row>
        </Container>
    );
};

export default Login;
