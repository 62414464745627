import React from 'react';
import { Outlet } from 'react-router-dom';

import { useUserContext } from '../contexts/UserContext.js';
import ChangePassword from '../components/ChangePassword/ChangePassword.js';

function ChangePasswordPage() {
  const user_context = useUserContext();
  const user = user_context.getUser();

  if (user && user.is_temporary_password){
    return <div>
      <ChangePassword/>
    </div>
  } else {
    return <Outlet/>
  }
}

export default ChangePasswordPage;
