import React from 'react';
import { Outlet } from 'react-router-dom';

import { useUserContext } from '../contexts/UserContext.js';
import AddStudent from '../components/AddStudent/AddStudent.js';

function AddStudentPage() {
  const user_context = useUserContext();
  const user = user_context.getUser();

  if (user && user.is_coach){
    return <div>
      <AddStudent/>
    </div>
  } else {
    return <Outlet/>
  }
}

export default AddStudentPage;
