import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { useUserContext } from '../../contexts/UserContext.js'


const PrivateRoute = () => {
  const user_context = useUserContext();

  if (!user_context.isUserLoggedIn()) return <Navigate to="/login" />;
  return <Outlet />;
};

export default PrivateRoute;
