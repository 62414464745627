import React, {useEffect, useRef } from 'react';
import { useUserContext } from '../../contexts/UserContext.js';
import './App.css';

import PrivateRoute from '../PrivateRoute/PrivateRoute.js';
import Navigation from '../Navigation/Navigation.js';

import LoginPage from '../../pages/LoginPage.js';
import HomePage from '../../pages/HomePage.js';
import AddStudentPage from '../../pages/AddStudentPage.js';
import ProfilePage from '../../pages/ProfilePage.js';
import ChangePasswordPage from '../../pages/ChangePasswordPage.js';

import { getCurrentUser } from '../../api/rest.js';

import {
  HashRouter as Router,
  Routes,
  Route,
} from "react-router-dom";

function App() {
  const user_context = useUserContext();
  const user = user_context.getUser();
  const initialized = useRef(false)

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true

      if (user_context.isUserLoggedIn()) {
        if (!user) {
          getCurrentUser().then((data) => {
            user_context.setUser(data)
          });
        }
      };
    }
  }, [user, user_context]);

  return (
    <div className="App">
      <Router>
          <Navigation/>
          <Routes>
            <Route path="login" element={<LoginPage/>}/>
            <Route element={<PrivateRoute />}>
              <Route index element={<HomePage/>}/>
              <Route path="profile" element={<ProfilePage/>}/>
              <Route path="students/add" element={<AddStudentPage/>}/>
              <Route path="change-password" element={<ChangePasswordPage/>}/>
            </Route>
          </Routes>
      </Router>
    </div>
  );
}

export default App;
