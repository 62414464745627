import React from 'react';

import { useUserContext } from '../contexts/UserContext.js';
import CoachDashboard from '../components/CoachDashboard/CoachDashboard.js';

const HomePage = () => {
  const user_context = useUserContext();
  const user = user_context.getUser();


  if (user && user.is_coach) {
    return (<div>
      <CoachDashboard />
    </div>);
  }

  return (<div>
    </div>);
}

export default HomePage;
