import React from 'react';
import Profile from '../components/Profile/Profile.js';

function ProfilePage() {
  return <div>
    <Profile/>
  </div>
}

export default ProfilePage;
