import { authRequest } from './auth.js'

const getCurrentUser = () => {
  return authRequest.get('/api/v1/users/me/').then(response=>{
    return Promise.resolve(response.data)
  }).catch((error)=> {
    return Promise.reject(error)
  });
}

const createUserAsStudent = (data) => {
  return authRequest.post('/api/v1/users/students/', data).then(response=>{
    return Promise.resolve(response.data)
  }).catch((error)=> {
    return Promise.reject(error)
  });
}


const updateCurrentUser = (data) => {
  return authRequest.patch('/api/v1/users/me/', data)
    .then(response=>{
      return Promise.resolve(response.data)
    }).catch((error)=> {
      return Promise.reject(error)
    });
}


const changeCurrentUserPassword = (data) => {
  return authRequest.post('/api/v1/users/me/change-password/', data).then(response=>{
    return Promise.resolve(response.data)
  }).catch((error)=> {
    return Promise.reject(error)
  });
}

const getUsers = () => {
  return authRequest.get('/api/v1/users/').then(response=>{
    return Promise.resolve(response.data)
  }).catch((error)=>{
    return Promise.reject(error)
  });
}

export { getCurrentUser, updateCurrentUser, createUserAsStudent, changeCurrentUserPassword, getUsers}
