import React, { createContext, useState, useContext } from "react";

import { loginUser as authLoginUser, logoutUser as authLogoutUser, isAccessTokenSet } from '../api/auth.js'
import { getCurrentUser } from '../api/rest.js'

const CURRENT_USER = 'current_user';

const UserContext = createContext(null);

export const UserProvider = ({ children }) => {
  const storedUser = (window.localStorage.getItem(CURRENT_USER));
  const [_user, _setUser] = useState((storedUser) ? JSON.parse(storedUser) : null);

  const isUserLoggedIn = () => {
    return isAccessTokenSet();
  }

  const getUser = () => {
    return _user;
  }

  const setUser = (data) => {
    _setUser(data);
    window.localStorage.setItem(CURRENT_USER, JSON.stringify(data));
  }

  const loginUser = (phone_number, password) => {
    return authLoginUser(phone_number, password).then((data)=> {
        getCurrentUser().then((data)=> {
            setUser(data);
            return Promise.resolve(data);
        }).catch((error)=>{
            return Promise.reject(error);
        });
    }).catch((error)=>{
        return Promise.reject(error);
    });
  }

  const logoutUser = () => {
    authLogoutUser();
    setUser(null);
  }

  return (
    <UserContext.Provider value={{ getUser, setUser, loginUser, logoutUser, isUserLoggedIn }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUserContext = () => {
  return useContext(UserContext);
};
