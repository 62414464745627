import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from "react-router-dom";

import { Row, Container, Button, Form, Col } from 'react-bootstrap';

import { useUserContext } from '../../contexts/UserContext.js'
import { NumericFormat } from 'react-number-format';
import { updateCurrentUser } from '../../api/rest.js'


const Profile = () => {
    const user_context = useUserContext();
    const user = user_context.getUser();
    const navigate = useNavigate();
    const [validated, setValidated] = useState(false);

    const [email, setEmail] = useState('');
    const [first_name, setFirstName] = useState('');
    const [middle_name, setMiddleName] = useState('');
    const [last_name, setLastName] = useState('');
    const [birth_date, setBirthDate] = useState('');
    const [weight, setWeight] = useState('');
    const [height, setHeight] = useState('');
    const [errorDisplay, setErrorDisplay] = useState("")

    const initialized = useRef(false)

    useEffect(() => {
        if (!initialized.current) {
            initialized.current = true

            if (user) {
                setEmail(user.email);
                setFirstName(user.first_name);
                setMiddleName(user.middle_name);
                setLastName(user.last_name);

                if (user.profile) {

                    setBirthDate(user.profile.birth_date);
                    setWeight(user.profile.weight);
                    setHeight(user.profile.height);
                }
            }
        }
    }, [user]);

    const goBack = (event) => {
        event.preventDefault();
        navigate(-1);
    }

    const onFormSubmit = (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.stopPropagation();
            setValidated(true);
        } else {
            const formData = {
                email: email,
                first_name: first_name,
                middle_name: middle_name,
                last_name: last_name,
                profile: {
                    birth_date: birth_date,
                    weight: weight,
                    height: height
                }
            };

            updateCurrentUser(formData).then((data)=> {
                user_context.setUser(data);
                setValidated(false);
            }).catch((error)=> {
                setErrorDisplay(error.response.data.detail)
            })
        }
    }

    return(
        <Container fluid>
            <Row className="justify-content-md-center mt-5">
                <Col xs={12} md={6}>
                    <Button variant="secondary" style={{display: 'flex', justifyContent: 'right'}} className="width: auto;" onClick={goBack}>
                        Go back
                    </Button>
                    <h2 className="text-center mb-4">Profile</h2>
                    <Form noValidate validated={validated} onSubmit={onFormSubmit} method='POST'>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Email</Form.Label>
                            <Form.Control type="email" value={email} required onChange={e => setEmail(e.target.value)}/>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicFirstName">
                            <Form.Label>First Name</Form.Label>
                            <Form.Control type="text" value={first_name} required onChange={e => setFirstName(e.target.value)}/>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicLastName">
                            <Form.Label>Last Name</Form.Label>
                            <Form.Control type="text" value={last_name} required onChange={e => setLastName(e.target.value)}/>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicMiddleName">
                            <Form.Label>Middle Name</Form.Label>
                            <Form.Control type="text" value={middle_name} onChange={e => setMiddleName(e.target.value)}/>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicBirthDate">
                            <Form.Label>Birth date</Form.Label>
                            <Form.Control type="date" value={birth_date} required onChange={e => setBirthDate(e.target.value)}/>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicWeight">
                            <Form.Label>Weight (kg)</Form.Label>
                            <NumericFormat
                                type='text'
                                value={weight}
                                onValueChange={value => setWeight(value.formattedValue)}
                                decimalScale={1}
                                required
                                className="form-control"
                                placeholder="Enter your weight (kg)"/>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicHeight">
                            <Form.Label>Height (cm)</Form.Label>
                            <NumericFormat
                                type='text'
                                value={height}
                                onValueChange={value => setHeight(value.formattedValue)}
                                decimalScale={0}
                                required
                                className="form-control"
                                placeholder="Enter your height (cm)"/>
                        </Form.Group>
                        <Button variant="primary" type="submit" className="w-100">
                            Update
                        </Button>
                        <p style={{color: 'red'}}>{errorDisplay}</p>
                    </Form>
                </Col>
            </Row>
        </Container>
    );
};

export default Profile;
