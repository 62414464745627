import React from 'react';
import Login from '../components/Login/Login.js';

function LoginPage() {
  return <div>
    <Login/>
  </div>
}

export default LoginPage;
